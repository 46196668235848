import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Code Xafly Auto Detailing
			</title>
			<meta name={"description"} content={"Відкрийте для себе блиск - Code Xafly, де блиск вашого автомобіля - наша пристрасть\n"} />
			<meta property={"og:title"} content={"Головна | Code Xafly Auto Detailing"} />
			<meta property={"og:description"} content={"Відкрийте для себе блиск - Code Xafly, де блиск вашого автомобіля - наша пристрасть\n"} />
			<meta property={"og:image"} content={"https://ua.codexafly.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 0 0" quarkly-title="Hero-18">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					lg-padding="0px 0 0px 0px"
					lg-margin="0px 0px 50px 0px"
					margin="0px 0px 80px 0px"
					align-items="center"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline1"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						padding="0px 250px 0px 250px"
						text-align="center"
						lg-padding="0px 0 0px 0"
						color="#111"
					>
						Code Xafly автосервіс
					</Text>
					<Text
						margin="0px 0px 50px 0px"
						font="--lead"
						color="#111"
						text-align="center"
						padding="0px 30px 0px 30px"
						lg-padding="0px 0 0px 0"
						lg-margin="0px 0px 30px 0px"
					>
						Пориньте у світ, де автомобільна елегантність поєднується з ретельною майстерністю. Автомайстерня Code Xafly - це ваш притулок для відновлення та підкреслення краси вашого автомобіля. Завдяки поєднанню досвіду та пристрасті ми розкриваємо справжній блиск екстер'єру та інтер'єру вашого автомобіля, залишаючи його в стані первозданної досконалості.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
						sm-align-self="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--color-primary"
							hover-color="#111"
							hover-background="rgba(82, 46, 224, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Звʼязатися з нами
						</Button>
					</Box>
				</Box>
				<Image
					src="https://ua.codexafly.com/img/1.jpg"
					background="--color-lightD1"
					border-radius="15px 15px 0 0"
					height="450px"
					object-fit="cover"
					object-position="0 70%"
					lg-height="300px"
					md-height="200px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="#dddddd"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="60%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Відчуйте блиск автосервісу Code Xafly
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						У Code Xafly ми виходимо за рамки звичайної автомийки. Наша команда кваліфікованих майстрів прагне відродити та зберегти естетичну привабливість вашого автомобіля. Від корекції фарби до омолодження салону - ми пишаємося тим, що перетворюємо ваш автомобіль на блискучий шедевр.
					</Text>
				</Box>
			</Box>
			<Text
				margin="0px 0px 26px 0px"
				font="--headline2"
				color="--dark"
				md-text-align="center"
				sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
			>
				Чому варто обрати Code Xafly Auto Detailing?
			</Text>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Одержимість досконалістю
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Наше прагнення до досконалості гарантує, що кожен сантиметр вашого автомобіля буде оброблений з максимальною ретельністю, в результаті чого ви отримаєте покриття, яке перевершить ваші очікування.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Точність у деталях
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Ми віримо в мистецтво деталізації, зосереджуючись на складних особливостях, щоб розкрити справжній характер вашого автомобіля, виділяючи його на дорозі.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Індивідуальні пакети
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Незалежно від того, чи потрібна вам комплексна деталізація або швидке підфарбовування, наш асортимент пакетів деталізації задовольняє різні потреби, забезпечуючи персоналізований підхід до кожного клієнта.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-15">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				justify-content="space-between"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
						md-text-align="left"
					>
						Відкрийте для себе переваги Code Xafly
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						- Революційне керамічне покриття: Захистіть лакофарбове покриття вашого автомобіля за допомогою нашого найсучаснішого керамічного покриття, що забезпечує тривалий блиск і захист від атмосферних впливів.
						<br />
						<br />
						- VIP-обслуговування: Насолоджуйтесь VIP-залом очікування з безкоштовними напоями, Wi-Fi та можливістю зазирнути вглиб автомайстерні, поки ваш автомобіль проходить процес перетворення.
					</Text>
				</Box>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box width="100%">
					<Image src="https://ua.codexafly.com/img/2.jpg" width="100%" />
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});